
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import { campHandleBrCellPhoneNumber, campHandleCPF, campHandleEmailVerification, campHandleEveryoneIsTrue, handleCreditCardNumber } from '@/composables/DataValidation';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { useAuthStore } from '@/store/AuthStore';


interface IUserData {
  cpf: string,
  name: string,
  email: string,
  tel: string,
  type: string,
  latestAccessCode: string,
  moderator: boolean,
  consultant: boolean,
  birth_date: string,
  card_number: string | null,
  card_pin: string | null,
}

interface IUserResponse {
  data: {
    error: boolean,
    message: string,
    data: IUserData
    errorCode: string
  },
  status: number
}

export default defineComponent({
  name: "CompanyPut",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  },
  setup() {
    const route = useRoute();
    const authStore = useAuthStore()

    const queryObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | IUserData
    }>({
      isLoading: false,
      noError: false,
      data: null
    })

    const loaderStore = useLoaderStore();

    onMounted(async () => {
      loaderStore.open()
      try {
        const result: IUserResponse = await axios.get(`/api/getCompetitorView/${id}`)
        const { data } = result
        if(!data.error) {
          queryObject.value.noError = true
          queryObject.value.data = data.data
        }
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
      }
    })

    const { id } = route.params;
    /** Warning of unfilled fields */
    const isRequiredField = ref(false)
    const isRequiredNameComplete = ref(false)
    const isInvalidFieldWarning = ref(false)
    const isInvalidField = ref<{ 
      cpf: boolean,
      mail: boolean,
      phoneNumber: boolean,
      card: boolean,
      pin: boolean,
     }>({
      cpf: false,
      mail: false,
      phoneNumber: false,
      card: false,
      pin: false,
    })

    /** Validate CPF */
    watch(() => queryObject.value.data?.cpf, () => {
      if(queryObject.value.data?.cpf) {
        isInvalidField.value.cpf = !campHandleCPF(queryObject.value.data.cpf)
      }
    })

    /** Validate email */
    // watch(() => queryObject.value.data?.email, () => {
    //   if(queryObject.value.data?.email) {
    //     isInvalidField.value.mail = !campHandleEmailVerification(queryObject.value.data.email)
    //   } else isInvalidField.value.mail = false
    // })

    /** Validate phone number */
    watch(() => queryObject.value.data?.tel, () => {
      if(queryObject.value.data?.tel) {
        isInvalidField.value.phoneNumber = !campHandleBrCellPhoneNumber(queryObject.value.data.tel)
      } else isInvalidField.value.phoneNumber = false
    })

    watch(() => queryObject.value.data?.card_number, () => {
      if(queryObject.value.data?.card_number) {
        isInvalidField.value.card = !handleCreditCardNumber(queryObject.value.data.card_number)
      } else isInvalidField.value.card = false
    })

    watch(() => queryObject.value.data?.card_pin, () => {
      if(queryObject.value.data?.card_pin) {
        isInvalidField.value.pin = queryObject.value.data.card_pin.length !== 19
      } else isInvalidField.value.pin = false
    })

    /** Reset warning */
    watch(() => queryObject.value.data, () => {
      if(isRequiredField.value)
        isRequiredField.value = false
      if(isInvalidFieldWarning.value)
        isInvalidFieldWarning.value = false
    },
    { deep: true })

    const { showTimeAlert } = useAlert()

    async function onSubmitForm() {
      
      const { data } = queryObject.value
      if(!data) {
        return router.push("/404")
      }
      if(
        !data.cpf.length ||
        !data.name.length ||
        !data.tel.length ||
        // !data.email.length ||
        !data.birth_date?.length ||
        (data.card_number && !data.card_pin?.length)
      ) {
        return isRequiredField.value = true
      }
      if(!data.name.includes(' ')) return isRequiredNameComplete.value = true
      if(campHandleEveryoneIsTrue(isInvalidField.value)) {
        isInvalidFieldWarning.value = true
        return isRequiredField.value = true
      }
      loaderStore.open()
      try {
        const { data } = queryObject.value
        if(data) {
          // const {tel, cpf, card_number, card_pin, ...res} = data
          const tel = campHandleBrCellPhoneNumber(data.tel, "unformatted")
          const cpf = campHandleCPF(data.cpf, "unformatted")
          const card_number_check = data.card_number ? handleCreditCardNumber(data.card_number, "unformatted") : null
          const card_number = typeof card_number_check == "string" ? card_number_check : null
          if(typeof tel === "boolean" || typeof cpf === "boolean") {
            loaderStore.close()
            return showTimeAlert("Algo deu errado!", "error")
          }
          const card_pin = (typeof card_number === "string" && card_number.length > 0) ? data.card_pin : null
          const putData = {
            ...data,
            tel,
            cpf,
            card_number,
            card_pin,
          }
          await axios.put(`/api/putCompetitor`, putData)
          showTimeAlert("Atualizado com sucesso!")
          return router.push("/vendedores")
        }
      } catch (error) {
        loaderStore.close()
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      }
    }

    return {
      queryObject,
      isRequiredField,
      isInvalidFieldWarning,
      isInvalidField,
      onSubmitForm,
      authStore,
      isRequiredNameComplete
    }
  }
})
